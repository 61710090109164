.grid-container h3 {
    margin-top: 0;
    font-family: 'Neue Aachen Pro Regular';
    font-size: 24px;
}



.contact.site-banner-bg {
  background-image: url(../../assets/bubba-contact.png); 
  background-position: center -60px;
 }
 
 @media only screen and (max-width:800px) {
  .contact.site-banner-bg {
    background-position: center -55px;
  }
}