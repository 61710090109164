/*Desktop View*/
.header {
  display: flex;
  background: var(--darkgrey);
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 80px;
}
.logo-nav {
  display: flex;
  align-items: center;

  color: var(--lightgray);
  font-family: 'Neue Aachen Pro Regular';
}
.logo-container {
  align-items: center;
  color: var(--lightgray);
  display: flex;
  font-family: 'Neue Aachen Pro Regular';
  font-size: 18px;
  font-weight: bold;
  justify-content: flex-start;
  padding-left: 15px;
  position: relative;
  text-decoration: none;
}

.logo-container a{
  color: var(--lightgray);
  font-family: 'Neue Aachen Pro Regular';
}

.nav-options {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  list-style-type: none;
  color: var(--lightgray); 
  font-family: 'Neue Aachen Pro Regular';
  font-size: 17px;
  text-decoration: none;
}

.nav-options a {
background: none;
color: var(--lightgray); 
font-family: 'Neue Aachen Pro Regular';
font-size: 17px;
padding: 0.5rem 1rem;
text-decoration: none;
}

.mobile-option {
  display: none;
}

.option :hover {
  color: rgba(255, 255, 255, 1);
}

.mobile-menu {
  display: none;
}
@media (max-width: 800px) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: 55px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: var(--darkgrey);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 3;
    align-content: center;
    padding-left: 0px;
  }

  .nav-options a {
    font-size: 18px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 10vw;
    padding: 30px 10px;
  }

 .mobile-menu {
  display: block;
  cursor: pointer;
} 
 
}

