

.shows.site-banner-bg {
    background-image: url(../../assets/black-sparrow2.png); 
    background-position: center -125px;
   }

   .show-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightgray);
 
  }

  .show-grid div {
    width: 32%;
   align-items: center; 
   border: 1px solid var(--lightgray);
   padding: 15px 0;
  }

  @media only screen and (max-width:600px) {
    .shows.site-banner-bg {
      background-position: center -5px;
    }
  }
