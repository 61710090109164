/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Neue Aachen Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Aachen Pro Regular'), url('NeueAachenProRegular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Neue Aachen Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Aachen Pro Italic'), url('NeueAachenProItalic.woff') format('woff');
  }
  


  h1 {
      font-family: 'Patua One';
      font-weight: 200;
      color:rgba(215, 201, 201, 0.735);
  }

  .container {
    max-width: 1200px;
    margin: auto;
}