.home.site-banner-bg {
  display: none;
}

.cover-bg {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}

.cover-bg:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../../assets/logo.png");
  background-size: contain;
  background-position:50% 50%;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  filter: opacity(11%);
  flex: 1 auto;
}

.brewcover {
  width: 300px;
    -webkit-box-shadow: 0 8px 15px rgb(0 0 0 / 85%);
    -moz-box-shadow: 0 8px 15px rgba(0,0,0,.85);
    box-shadow: 0 8px 15px rgb(0 0 0 / 85%);
    border: 1px black solid;
}

.grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: 1fr;
}

.grid-container h3 {
  margin-top: 0;
}

.inner-grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: 1fr;
}


.content {
  color: var(--white);
  max-width: 900px;
  margin: auto;
  padding: 15px;
}

.list li {
  padding: 10px 0;
}

.list i {
  padding-right:  10px;
}
  
@media screen and (max-width: 800px) {
  .cover-bg{
    font-size: 1rem;
}

.grid-container h3 {
  margin-top: 0;
}

.home.site-banner-bg {
  background-image: url(../../assets/brewTrainCover-sm.png);
  background-position: center -90px;
  display: block;
  height: 295px;
  }

 .grid-container > div:nth-child(1) {
    display: none;
  }

.inner-grid-container {
  gap: 0px;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  max-width: 400px;
  margin: auto;
}

}
