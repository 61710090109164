
.about .site-banner-bg {
  background-image: url(../../assets/about-bubba.jpeg);
  background-position: center -215px;
 }

 .bio {
  max-height: 200px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.panel-wrapper {
  position: relative;
}

.btn {
    color: var(--goldenrod);
    background: none;
    right: 0;
    padding: .5em;
    text-decoration: none;
    width: 30%;
}
.show, .hide {
  position: absolute;
  bottom: -1em;
  z-index: 100;
  text-align: center;
}

.hide {display: none;}
.show:target {display: none;}
.show:target ~ .hide {
  display: block;
bottom: 35px;
}
.show:target ~ .bio{
   max-height: 2000px; 
}
.show:target ~ .fade {
  margin-top: 0;
  bottom: 35px;
  height: 35px;
}

.fade {
  background-image: linear-gradient(to bottom, transparent, #383838);
  height: 100px;
  margin-top: -100px;
  position: relative;
}

.gallery-card {
  width: 240px;
  height: 300px;
  background: transparent;
  perspective: 1000px;
}

.gallery-card-inner {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.gallery-card:hover .gallery-card-inner {
  transform: rotateY(180deg);
}

.gallery-card-front, .gallery-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.gallery-card-back  {
  background-color:black;
  color: white;
  transform: rotateY(180deg);
}

.gallery-card img {
  max-width: 180px;
}
.gallery p {
  font-size: 14px;
}
.gallery h3 {
color: var(--goldenrod);
}


@media only screen and (max-width:800px) {

  .about.site-banner-bg {
    background-position: center -70px;
  }
}