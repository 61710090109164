footer, .footer {
    color: var(--lightgray);
    padding: 0 !important;
  }

  .copyright {
    flex-grow: 1;
    font-size: 12px;
    font-family: 'Neue Aachen Pro Regular';
}

  .social-media {
    flex-grow: 4;
    display: flex;
    margin-right: 30px;
    justify-content: flex-end;
}

  .icon {
    color: var(--white);
    margin-left: 10px;
  }

  .flex-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
  }

@media screen and (max-width: 1024px) {
    footer, .footer {
      padding: 0 15px !important;
  }
  }